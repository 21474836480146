.loading-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}
.loading-container {
  position: fixed;
  //width: 100vw;
  //height: 100vh;
  //width: 100px;
  //height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  img {
    //margin-top: -100px;
    width: 80px;
  }
  .text {
    color: #363636;
    margin-top: 30px;
    font-size: 25px;
  }
  svg{
    transform: scale(.6);
  }
}
