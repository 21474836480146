
html{
  font-size: 16px;
}
body{
  font-size: 16PX;
  overflow-x: hidden;

  *{
    box-sizing: border-box;
  }

}
input{
  outline: none;
}
a {
  text-decoration: none;
  color: inherit;
}

.loading{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.flex{
  display: flex;
}
.center {
  justify-content: center;
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-around {
  justify-content: space-around;
}
.justify-between {
  justify-content: space-between;
}
.align-start {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
